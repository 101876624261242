import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap } from "gsap";
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { isSmartphone, isSmartphoneOnlyWidth, isTouch } from '../_app/cuchillo/core/Basics';
import { Draggable } from "gsap/Draggable";

class ScrollItem__EspaciosWow extends VScroll_Item {
    _call;
    _slider;
    hasHiddenEnabled = false;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        if(!isSmartphoneOnlyWidth) {
            this._slider = new SliderScroll(GetBy.class("__slider", __link)[0], {
                interaction: true,
                hasScrollbar: true,
                itemClass: ScrollItem__EspaciosWow__Item,
                endOutside: true,
                domControls: GetBy.class("__controls", __link)[0],
            });

            this._call = () => this.loop();
            this.onShow = () => {
                gsap.ticker.add(this._call);
            };

            this.onHide = () => {
                gsap.ticker.remove(this._call);
            };
        } else {
            const x1 = GetBy.selector("[scroll-slider-item]", __link)[0].getBoundingClientRect().left;
            const x2 = GetBy.selector("[scroll-slider-item]", __link)[1].getBoundingClientRect().left;
            const gridWidth = x2 - x1;
            
            Draggable.create(GetBy.class("__holder", __link)[0], {
                type:"x", 
                edgeResistance:0.85,
                dragResistance: 0, 
                bounds:GetBy.class("__slider", __link)[0], 
                inertia:true,
                snap: {
                    x: function(endValue) {
                        return Math.round(endValue / gridWidth) * gridWidth;
                    }
                }
            });
        }
    }

    resize(w, h) {
        super.resize(w, h);
        if (this._slider) this._slider.resize();
    }

    loop() {
        if (this._slider) {
            this._slider.loop();
        }
    }

    dispose() {
        gsap.ticker.remove(this._call);
        if (this._slider) this._slider.dispose();
        super.dispose();
    }
}

class ScrollItem__EspaciosWow__Item extends VScroll_Item {

    info;
    hasHiddenEnabled = false;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this.onMove = () => {
            const progress = Maths.precission(this.progressInside, 2);
            const norm = Maths.normalize(1, 1.04, progress);
            const opacity = Maths.lerp(0, 1, norm);
            this.item.style.opacity = opacity;


        }
    }
}

Scroll._registerClass('SliderEspaciosWow', ScrollItem__EspaciosWow);
